import React from 'react';
import PropTypes from 'prop-types';

import { Picture } from 'components/Picture';
import TypeIcon from 'components/TypeIcon';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import LogoNewsNow from '../Logo/NewsNow';
import LogoNewsSpecialReport from '../Logo/NewsSpecialReport';

const block = 'mini-player';

const LOGOS = {
  newsnow: LogoNewsNow({ block }),
  newsspecialreport: LogoNewsSpecialReport({ block }),
};

/**
 * Implements the MiniPlayerTease page and content.
 * @param {object} props props object
 * @param {string} props.brand brand of the content
 * @param {boolean} props.isFlagged if the content is flagged
 * @param {boolean} props.useAnimatedTease if the content uses an animated tease
 * @param {string} props.headline headline text
 * @param {string} props.fallbackHeadline fallback headline text
 * @param {object} props.staticTeaseImage static tease image
 * @param {object} props.fallbackTeaseImage fallback tease image
 * @param {string} props.url url to redirect to
 */
function MiniPlayerTease({
  brand,
  isFlagged,
  useAnimatedTease,
  headline,
  fallbackHeadline,
  staticTeaseImage,
  fallbackTeaseImage,
  url,
}) {
  /**
   * Get the tease image
   */
  const getTeaseImage = () => {
    if (!useAnimatedTease && staticTeaseImage.url) {
      // if animated tease isn't being used and static tease image url exists
      return staticTeaseImage;
    }
    return fallbackTeaseImage;
  };

  const headlineText = headline || fallbackHeadline;
  const teaseImage = getTeaseImage();

  const pictureData = {
    className: 'db',
    responsiveFlavors: {
      s: AIMS_FLAVORS.MINI_PLAYER_TEASE_140x80,
    },
    computedValues: {
      teaseImage: {
        url: {
          primary: teaseImage.url || undefined,
        },
        altText: teaseImage.alt || '',
      },
    },
    layout: 'responsive',
    flavor: AIMS_FLAVORS.MINI_PLAYER_TEASE_140x80,
  };

  const icid = 'now_hp_header';

  return (
    <div className="mini-player" data-testid="NewsNow-page">
      <button
        className="mini-player__button"
        onClick={() => {
          window.location = url;
        }}
        data-icid={icid}
        type="button"
      >
        {pictureData.computedValues.teaseImage.url.primary && (
          <div className="mini-player__tease">
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Picture testId="mini-player__tease-picture" {...pictureData} />
            <TypeIcon className="mini-player__typeicon" type="video" />
          </div>
        )}
        <div className="mini-player__content">
          {brand && LOGOS[brand]}
          <h1 className="mini-player__headline">
            {isFlagged && (
              <span className="mini-player__live-flag">
                LIVE
                :&nbsp;
              </span>
            )}
            {headlineText}
          </h1>
        </div>
      </button>
    </div>
  );
}

MiniPlayerTease.propTypes = {
  brand: PropTypes.string,
  isFlagged: PropTypes.bool,
  useAnimatedTease: PropTypes.bool,
  headline: PropTypes.string,
  fallbackHeadline: PropTypes.string,
  staticTeaseImage: PropTypes.objectOf(PropTypes.string),
  fallbackTeaseImage: PropTypes.objectOf(PropTypes.string),
  url: PropTypes.string,
};

MiniPlayerTease.defaultProps = {
  brand: 'newsnow',
  isFlagged: true,
  useAnimatedTease: true,
  headline: null,
  fallbackHeadline: null,
  staticTeaseImage: {},
  fallbackTeaseImage: {},
  url: 'https://www.nbcnews.com/now',
};

export default MiniPlayerTease;
